.promotionInput {
  height: 2.6em;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
  border: 1px dashed var(--border-color);
  border-radius: 5px;
  width: 78%;
}

.applyBtn {
  height: 2.6em;
  width: 20%;
  padding-top: 0.4em;
}

.codeIcon {
  width: 20px;
  margin-right: 0.5em;
}