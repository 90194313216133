.btnSwitch {
  font-size: 0.75em;
  padding: 0.4rem;

  &.left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.bankBanner {
  border: 1px solid #e1e0e0;
  border-radius: 5px;
  background-color: #fafafa;
  color: rgba(6, 9, 32, 0.44);
  padding: 0.5rem 1rem ;
}

.arrowStyling {
  margin-left: 2em;
  margin-bottom: -2px;
  z-index: 5;
}