$green: #2F7D22;

.parentContainer {
  background: #D2E7CF;
  background-image: url('../../../public/cost_summary_toggle_background.png');
  background-size: 100%;
  background-size: cover;
  border-radius: 5px;
  height: 68px;
}

.colorGreen {
  color: $green;
}

.colorGrey {
  color: #575757;
}

.titleSavingsAmount {
  font-style: normal;
  font-weight: 900;
  line-height: 25px;
}

.titleSavingsDescription {
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.savingsBenefitsLineItem {
  gap: 8px;
  padding: 0px;
  height: 19px;
}

.savingsBenefitsLineItemText {
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.percentageIcon {
  height: 38px;
  width: 37px;
}

.verticalDivider {
  border-right: 1px solid #BCD3B8;
}

.checkMarkIcon {
  width: 13px;
  height: 9px;
}

.switchToAnnualButton {
  background-color: $green;
  color: #FFFFFF;
  padding: 4px 12px;
}

.switchToAnnualButton:hover {
  background: #2F7D22;
  color: #FFFFFF;
}
