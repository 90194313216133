.illustrationContainer {
  min-height: 200px;
  height: 30vh;
  background-color: #F0F0F0;
}

.modalOverride {
  :global(.modal-body) {
    padding-right: 0;
    padding-left: 0;
  }
}

.contentContainer {
  padding: 1rem 1rem 2em;
  text-align: center;
}

.caption {
  margin-top: 1.8em;
  color: #757D8A;
  max-width: 25em;
  margin-left: auto;
  margin-right: auto;
}

.callToActionBtn {
  padding: 0.5em 5.5em;
}