@use "../../styles/variables" as v;

:root {
  --link-color: rgb(243, 16, 61);
  --border-color: #f3103d;
  --btn-main-caption-color: #f3103d;
  --light-background: #fff5f6;
  --main-color: #f3103d;
}

.container {
  width: 100%;
}

.header {
  width: 100%;
  position: absolute;
  top: 0.25rem;
  padding: 0 6% 0 5%;
  z-index: 5;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 12em;
  width: 12em;
}

.externalLogo {
  max-width: 12em;
}

@media (max-width: v.$breakpoint-mobile) {
  .logo {
    height: 9em;
    width: 9em;
  }

  .header {
    position: static;
  }

  .externalLogo {
    max-width: 9em;
  }
}