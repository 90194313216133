@use '../../styles/variables' as v;

.container {
  padding: 2em;
  min-height: 25em;
  max-width: 1138px;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  box-shadow: 0 2px 3px 2px rgb(0 0 0 / 8%);
  background-color: #fff;
  border-radius: 0.5rem;
  gap: 2em;
}

.outsideContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.promotionDetailsContainer {
  padding: 1em 0;
}

.promotionAmountCaption {
  font-size: 2em;
  color: #2F7D22;
  font-weight: 900;
  letter-spacing: -0.3px;
}

.promotionParagraph {
  line-height: 28.8px;
}

.benefitList {
  list-style: none;
  padding: 0;

  .itemCaption {
    font-weight: 200;
  }
}

.callToActionContainer {
  bottom: 0;
  right: 0;
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  margin-top: 4em;
}

.callToActionBtn {
  padding: 0.5em 4em;
}

.showResponsive {
  display: none;
}

.showLargeScreen {
  display: block;
}

.illustrationContainer {
  background-color: #fff5f7;
  min-width: 30%;
  width: 500px;
}

@media (max-width: v.$breakpoint-mobile) {
  .container {
    flex-direction: column;
    box-shadow: none;
    width: 100%;
    padding: 0;
    gap: 1em;
    position: static;
    transform: none;
  }

  .outsideContainer {
    margin-top: 0;
    width: 100%;
    height: initial;
    position: initial;
  }

  .promotionDetailsContainer {
    padding: 0 1em;
  }

  .illustrationContainer {
    display: flex;
    width: 100%;
    min-width: 100%;
    min-height: 300px;

    svg {
      margin-top: 1.5em;
    }
  }

  .promotionTittleSection {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 1.5em;

    .tittleCaption {
      order: 1;

      p {
        display: flex;
        flex-direction: column;
      }
    }

    .tittleIcon {
      margin-top: 1.5em;
      order: 2;
    }
  }

  .callToActionContainer {
    margin-top: 1.5em;
    margin-bottom: 4em;
    position: initial !important;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    button {
      width: 100%;
      padding: 1em 0;
    }

    .secondary {
      order: 2;
    }

    .callToActionBtn {
      order: 1;
    }
  }

  .showResponsive {
    display: block;
  }

  .showLargeScreen {
    display: none;
  }
}

