@use '../../styles/variables' as v;

.callToActionBtn {
  width: 100%;

  &:not(:disabled), {
    color: #ffffff !important;
    background-color: var(--border-color) !important;
  }
}

@media (max-width: v.$breakpoint-mobile) {
  .callToActionBtn {
    margin-bottom: 4em;
  }
}