.separator {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 1rem 0;
}

.caption {
  color: rgba(0, 0, 0, 0.4);
}

.verificationChargeCaption {
  font-size: 0.65rem;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  top: 1.5rem;
}

.percentageIcon {
  height: 16px;
  width: 17px;
}
