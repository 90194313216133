.form-control {
  border-color: #c8c8c8;
  outline: none;
  padding: 10px 15px;
  height: 3em;
  font-size: 0.875em;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: #c8c8c8;
  }
}