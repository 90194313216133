@use '../../../styles/variables' as v;

.bankInformationCaption {
  color: rgba(6, 9, 32, 0.44);
}

.captionTittle {
  font-size: 10px;
}

.errorMessage {
  color: rgb(243, 16, 61);
  padding: 0.5rem;
  background-color: rgb(255, 245, 246);
}

@media (max-width: v.$breakpoint-mobile) {
  .mFullWidth {
    width: 100% !important;
  }
}
