@use '../../styles/variables' as v;

.container {
  margin: auto;
  padding: 2em;
  min-height: 25em;
  max-width: 1138px;
  width: 90%;
  box-shadow: 0 2px 3px 2px rgb(0 0 0 / 8%);
  background-color: #fff;
  border-radius: 0.5rem;
  gap: 2em;
}

.outsideContainer {
  width: 100vw;
  margin-top: 9em;
  margin-bottom: 5em;
}

.promotionDetailsContainer {
  padding: 1em;
  border: 1px solid var(--border-color);
  border-radius: 0.5em;
  position: relative;
}

.planBenefitsContainer {
  justify-content: space-between;
  width: 100%;
}

.planBenefitContainer {
  background-color: #fafafa;
  width: 48%;
  padding: 1.5em 2em;
  border-radius: 0.5em;

  p {
    color: #7d7d7d;
  }
}

.planBenefitTittleContainer {
  gap: 1em;
  width: 100%;
  align-content: center;
  padding-bottom: 1em;

  &.separator {
    border-bottom: 1px solid rgba(125, 125, 125, 0.25);
  }

  p {
    margin-bottom: 0;
  }
}

.planBenefitListContainer {
  padding-top: 1em;
  overflow: hidden;
  transition: max-height 1s;
  max-height: 1000px;

  &.collapsed {
    max-height: 0;
    padding-top: 0;
  }
}

.chevronIcon {
  transition: trasnform 1s;
  transform: initial;

  &.collapsed {
    transform: rotate(0.5turn);
  }
}

.promotionAmountCaption {
  font-size: 2em;
  color: #2F7D22;
  font-weight: 900;
  letter-spacing: -0.3px;
}

.promotionParagraph {
  line-height: 28.8px;
}

.benefitList {
  list-style: none;
  padding: 0;
  color: #7d7d7d;

  .itemCaption {
    font-weight: 200;
  }
}

.callToActionContainer {
  bottom: 0;
  right: 0;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  margin-top: 4em;
}

.billingPeriodCaption {
  max-width: 20em;
  color: #9ba1ab;
}

.callToActionBtn, .secondary {
  padding: 0.5em 2em;
}

.showResponsive {
  display: none;
}

.showLargeScreen {
  display: block;
}

.illustrationContainer {
  background-color: #fff5f7;
  min-width: 30%;
  width: 500px;
}

.checkmarkButton {
  position: absolute;
  top: 1em;
  right: 1em;
}

.phoneIllustration {
  flex-basis: 140px;
}

.promotionCostSection {
  display: flex;
  align-items: center;

  .amountsContainer {
    gap: 1em;
    display: flex;
    border-right: 1px solid #E8E8E8;
    padding-right: 16px;
    margin-right: 16px;
    align-items: center;
  }

  .percentageDiscount {
    background: #2f7d22;
    border-radius: 5px;
    color: #fff;
    padding: 0.125em 0.313em;
  }

  .noDiscountedPrice {
    color: #9ba1ab;
    font-size: 2.25em;
    text-decoration: line-through;
  }

  .finalPrice {
    font-size: 2.25em;
    color: #575757;

    .interval {
      font-size: 0.5em;
    }
  }
}

.buttonContainer {
  align-items: center;
}

.noShadow {
  box-shadow: none;
}

@media (max-width: v.$breakpoint-mobile) {
  .container {
    flex-direction: column;
    box-shadow: none;
    width: 100%;
    padding: 0 1em;
    gap: 1em;
    position: static;
    transform: none;
  }

  .planBenefitsContainer {
    flex-direction: column;
    gap: 1em;
  }

  .planBenefitContainer {
    width: 100%;
  }

  .outsideContainer {
    margin-top: 0;
    width: 100%;
    height: initial;
    position: initial;
  }

  .promotionDetailsContainer {
    padding: 1em;
  }

  .illustrationContainer {
    display: flex;
    width: 100%;
    min-width: 100%;
    min-height: 300px;

    svg {
      margin-top: 1.5em;
    }
  }

  .promotionTittleSection {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 1.5em;

    .tittleCaption {
      order: 1;

      p {
        display: flex;
        flex-direction: column;
      }
    }

    .tittleIcon {
      margin-top: 1.5em;
      order: 2;
    }
  }

  .callToActionContainer {
    margin-top: 1.5em;
    margin-bottom: 4em;
    position: initial !important;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    justify-content: space-between;

    button {
      width: 100%;
      padding: 1em 0;
    }

    .secondary {
      order: 2;
    }

    .callToActionBtn {
      order: 1;
    }
  }

  .showResponsive {
    display: block;
  }

  .showLargeScreen {
    display: none;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .billingPeriodCaption {
    max-width: 100%;
  }

  .amountsContainer {
    margin-right: 0 !important;
    border-right: none !important;
  }

  .promotionCostSection {
    flex-direction: column;
  }
}

