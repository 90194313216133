.ribbon {
  width: 30px;
  height: 30px;
}

.bannerContainer {
  min-height: 4em;
  width: 100%;
  border: 1px solid #e1e0e0;
  background-color: #fafafa;
  padding: 1rem;
}

.bannerCaption {
  color: rgba(6, 9, 32, 0.44)
}