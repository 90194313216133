.caption {
  color: rgba(0, 0, 0, 0.4);
}

a {
  color: var(--link-color);

  &:hover {
    color: var(--link-color);
  }
}