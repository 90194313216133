@use '../../styles/variables' as v;

.container {
  padding: 2.5em;
  min-height: 25em;
  max-width: 850px;
  width: 90%;
  margin: 8em auto 2em;
  box-shadow: 0 2px 3px 2px rgb(0 0 0 / 8%);
  background-color: #fff;
  border-radius: 0.5rem;
}

@media (max-width: v.$breakpoint-mobile) {
  .container {
    padding: 0;
    min-height: 25em;
    max-width: initial;
    width: initial;
    margin: initial;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
  }

  .subContainer {
    padding: 1.5em;
    max-width: 850px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0 2px 3px 2px rgb(0 0 0 / 8%);
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .mmt1 {
    margin-top: 8em;
  }

  .mmt2 {
    margin-top: 2em;
  }

  .mmb1 {
    margin-bottom: 2em;
  }
}