@use '../../styles/variables' as v;

.inputSpace {
  width: calc(50% - 0.5em);
}

@media (max-width: v.$breakpoint-mobile) {
  .mFullWidth {
    width: 100%;
  }

  .mFlexColumn {
    flex-direction: column;
  }

  .mt1 {
    margin-top: 1em;
  }
}
