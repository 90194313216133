@use '../../styles/variables' as v;

.errorOnInput {
  border-color: var(--border-color) !important;
}

.errorCaption {
  color: var(--border-color);
  position: absolute;
}

@media (max-width: v.$breakpoint-mobile) {
  .mFullWidth {
    width: 100%;
  }

  .mFlexColumn {
    flex-direction: column;
  }
}