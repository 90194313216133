.container {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 0.5rem;
}

.discountAmountContainer {
  padding: 0.5rem 1rem;
  background-color: #fff5f6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
}

.appliedLabel {
  color: #fff;
  background-color: var(--border-color);
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  height: fit-content;
}

.amount {
  color: var(--border-color);
  margin-right: 0.25em;
}
