@use "variables" as v;

.btn {
  $shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  @include font-family-inter-semibold();
  box-shadow: $shadow;
  padding: 10px 15px;
  cursor: pointer;

  &--fw {
    width: 100%;
  }

  &--dotted {
    background-color: white;
    color: $primary;
    border-style: dashed;
    box-shadow: $shadow;
  }

  &:hover {
    text-decoration: none;
  }

  &.btn-primary {
    background-color: #fff;
    border-color: var(--border-color);
    color: var(--btn-main-caption-color);
    border-radius: 5px;
    border-width: 1px;

    &.filled {
      color: #ffffff !important;
      background-color: var(--border-color) !important;
    }

    &.btn-toggle {
      background-color: var(--light-background);
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border: 1px solid #9ba1ab;
    border-radius: 5px;
    color: #9ba1ab;

    &.caption {
      border: none;
      color: var(--border-color);
      box-shadow: none;
    }

    &.caption-with-bordered-responsive {
      color: var(--border-color);
      @media (max-width: v.$breakpoint-mobile) {
        box-shadow: none;
      }
    }
  }

  &.btn-primary {
    z-index: 2;
  }

  &.btn-secondary {
    z-index: 1;
  }

  &.btn-action {
    border-color: var(--border-color);
    background-color: var(--border-color);
    color: #fff;
  }

  &[disabled] {
    background-color: #fafafa;
    color: #777;
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, .2);
  }
}