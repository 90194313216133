@use '../../styles/variables' as v;

.phoneInputClass {
  max-width: 15em;
  display: flex;
  flex-direction: row;
}

.coverageCaption {
  margin-right: 1em;
}

@media (max-width: v.$breakpoint-mobile) {
  .mFlexColumn {
    flex-direction: column;
  }
}